.project-list {
  a {
    text-decoration: none;
    color: $black;
    font-weight: 500;
    font-size: 1.2em;
    flex: 1;
    display: flex;
    height: 80px;
    align-items: center;
    padding-left: 1rem;
    flex-basis: 60%;
  }

  .project {
    display: flex;
    flex: 1;
    margin-bottom: 1.2rem;
    align-items: center;
    padding-right: 0.1rem;
    border-left: 4px solid $gray-400;

    &.success {
      border-left: 4px solid $green;
    }

    &.failure {
      border-left: 4px solid $red;
    }
  }

  .build-count {
    font-weight: 400;
    font-size: 0.8em;
    flex: 0;
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4px;

    .count {
      font-family: $font-family-monospace;
      font-size: 1.3em;
      font-weight: 500;
    }

    h6 {
      letter-spacing: 0.5px;
      font-size: 1em;
      margin-bottom: 3px;
      font-weight: 600;
      color: lighten($black, 30%);
      text-transform: uppercase;
    }
  }

  .card-body {
    padding: 0;
    padding-left: 1rem;
  }

  .card {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 75px;

    &:hover {
      border-right-color: $gray-500;
      border-top-color: $gray-500;
      border-bottom-color: $gray-500;

      // border-left-color: $gray-500;

      // background: $gray-100;
    }
  }
}
