.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 12px;
  padding-right: 12px;
}

.navbar {
  border-bottom: 1px solid $gray-300;
  box-shadow: 0 3px 4px $gray-200;
  font-weight: 500;
  height: 70px;
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 1.7rem;

  .nav-item {
    margin-top: -2px;
    padding-right: 0.3rem;
    padding-left: 0.3rem;

    [aria-current='page'] {
      border-bottom: 2px solid $blue;
    }
  }

  .nav-item a {
    padding-top: 12px;
    display: inline-flex;
    align-items: center;
    flex: 1;
    color: $black;
    height: 70px;
    border-bottom: 2px solid transparent;

    &:hover {
      color: $blue;
    }
  }

  .navbar-brand {
    color: $black;
    font-weight: 600;
    letter-spacing: 0.58px;
    margin-top: -1px;
  }
}
