.nav-tabs {
  // border-bottom: 2px solid $gray-300;
  border-bottom: 0;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    z-index: 0;
    left: 0px;
    bottom: 0px;
    height: 2px;
    width: 100%;
    background-color: $gray-300;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
  border: none;
  // border-bottom: 2px solid $blue;
  background: transparent;
}

.nav-tabs .nav-link {
  display: flex;
  flex: 1;
  align-items: center;
  color: $black;
  border: none;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.9rem;

  div {
    margin-top: 2px;
    margin-left: 0.6rem;
  }

  &:hover {
    color: $blue;
    border-bottom: 0;
  }
}

.nav-tabs .nav-item {
  margin-bottom: 0;
  margin-right: 0.8rem;
}

.nav-tabs [aria-current='page'] {
  border-bottom: 2px solid $blue;
  color: $blue;
  z-index: 1;
  position: relative;

  &:hover,
  &:focus {
    border-bottom: 2px solid $blue;
  }
}
