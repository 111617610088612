.recharts-cartesian-axis-tick {
  font-size: 0.9em;
  font-family: 'IBM Plex Mono';

  font-weight: 500;
}

.recharts-cartesian-axis-tick-value {
  tspan {
    fill: $black;
  }
}

.recharts-layer text {
  font-family: 'IBM Plex Mono';

  font-size: 1em;
  word-break: break-word;
}

.recharts-layer {
  word-break: break-word;
}

.chart-heading {
  // font-family: 'IBM Plex Mono';
  font-size: 2.4em;
  font-weight: 400;

  .total-size {
    color: $gray-600;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
  }
}
