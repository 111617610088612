.table thead {
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.3px;

  th {
    // border-bottom: 2px solid $gray-200;
    border-bottom: none;
    // padding-bottom: 8px;
    font-weight: 700;
  }
}

.table-hover tbody tr {
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }
}

.card .table {
  margin-bottom: 0;
}

.table th {
  border-top: none;
}

