.tree-map {
  align-items: stretch;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  .map {
    flex: 1;
  }
}

