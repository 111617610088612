@import '~bootstrap/scss/functions';
@import 'balloon';
@import 'variables';
@import 'bootstrap';
@import 'navbar';
@import 'project-list';
@import 'table';
@import 'builds';
@import 'tree-map';
@import 'chart';
@import 'tabs';
@import 'loader';
@import 'setup';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $gray-100;
  text-rendering: optimizelegibility;
  overflow-y: scroll;
  font-weight: 400;
  // zoom: 1.1;
}

a,
.btn,
button {
  font-weight: 500;
}

label {
  font-weight: 600;
}

h1,
h2,
h3 {
  font-weight: 600;
  letter-spacing: 0.8px;
  margin-bottom: 0;
}

h4,
h5 {
  font-weight: 700;
  letter-spacing: 0.5px;
}

.page-title {
  margin-bottom: 1.5rem;
}

pre,
code,
.monospace {
  font-weight: 500;
  font-family: $font-family-monospace;
}

.list-group {
  [aria-current='page'] {
    color: $blue;
    font-weight: 600;
    // color: $white;
  }
}

.btn-secondary {
  background: $white;
  color: $blue;
  border-color: $gray-300;

  &:hover {
    border-color: $gray-400;
    background: $gray-200;
    color: $blue;
  }
}

.form-control {
  border-bottom-width: 2px;
  color: $black;

  &:focus {
    color: $black;
  }
}

.small-tree-map {
  height: 40px;
  width: 50px;
  background: rgb(148, 1, 178);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 3px;
  border: 1px solid $gray-200;

  .one {
    background: rgb(40, 110, 250);
    grid-column: 1 / 3;
    grid-row: 1;
  }
  .two {
    background: rgb(23, 198, 93);
    grid-column: 2 / 4;
    grid-row: 1 / 3;
  }
  .three {
    background: rgb(255, 139, 71);
    grid-column: 1;
    grid-row: 2 / 5;
  }
  .four {
    background: rgb(253, 58, 87);
    grid-column: 3;
    grid-row: 3;
  }
  .five {
    backround: rgb(148, 1, 178);
    grid-column: 2;
    grid-row: 4;
  }
  .six {
    background: rgb(255, 185, 72);
    grid-column: 3;
    grid-row: 4;
  }
}

.badge-pill {
  text-transform: uppercase;
}

.danger {
  color: $red;
  font-weight: 500;
}

// .success {
//   color: $green;
//   font-weight: 500;
// }

.commit-message {
  font-weight: 400;
  color: $gray-700;
  margin-left: 0.4rem;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tooltip {
  // background: lighten($black, 20%);
  background: rgba(60, 60, 60, 0.9);
  color: $white;
  border-radius: 3px;
  padding: 1rem;
  font-family: 'IBM Plex Mono';
  font-size: 0.9em;
  font-weight: 500;
  position: relative;
  z-index: 1;

  &:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: solid rgba(60, 60, 60, 0.9) 10px;
    top: -10px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }
  // border: 1px solid $gray-500;
  // box-shadow: 5px 5px 5px $gray-400;
}

.tooltip-line {
  background: $white;
  color: $black;
  border: 1px solid $gray-300;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-family: 'IBM Plex Mono';
  font-size: 0.9em;
  font-weight: 500;
  position: relative;
  z-index: 1;
  // display: flex;
  // flex: 1;

  .right-content {
    padding-right: 0.8rem;
  }

  .left-content {
    padding-left: 0.8rem;
    padding-right: 2rem;

    img {
      margin-bottom: 0.5rem;
    }
    // margin-right: 1rem;
  }

  .tooltip-status {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

[data-balloon]::before,
[data-balloon]::after {
  transition-delay: 0.15s;
  white-space: pre;
  font-family: 'IBM Plex Mono' !important;
  font-weight: 500 !important;
}

[data-balloon]::after {
  background: rgba(60, 60, 60, 0.9);
}

[data-balloon]:hover::before {
  opacity: 0.82;
}

.github-button {
  min-width: 50px;
  padding: 1rem;
  display: flex;
  flex: 1;
  align-item: center;
  justify-content: center;
  color: $white;
  background: lighten($black, 10%);
  border-radius: 3px;
  font-size: 1.1em;

  div {
    margin-top: -1px;
    margin-left: 1rem;
  }

  &:hover {
    color: $white;
  }
}

.alert-warning {
  border: 1px solid $gray-300;
  border-left: 4px solid $yellow;
  background: $white;
  color: $black;
}

.badge-pill {
  font-family: 'Roboto', san-serif !important;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0.3rem 0.4rem;
  border-radius: 6px;
  // width: 30px;
  // height: 30px;

  // padding: 0.5rem;

  &.success {
    background: $green;
  }

  &.failure {
    // background: $red;
    color: $red;
  }
}

.link-to-repo {
  font-size: 1.2rem;
  letter-spacing: 0;
}

.progress-container {
  // display: flex;
  // flex: 1;
  align-items: center;
}

.progress-small {
  height: 10px;
  opacity: 0.8;
}

.comparing-to {
  font-size: 0.8em;
}
