.code-branch code {
  background: $gray-200;
  color: $black;
  font-size: 0.9em;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0.1rem;
  border-radius: 3px;
}

.card-title {
  letter-spacing: 0.6px;
}

table.align-middle {
  tbody td {
    vertical-align: middle;
  }
}

.commiter-name {
  font-size: 0.9em;
  margin-top: 2px;
  vertical-align: middle;
  font-weight: 500;
}

.relative-date {
  font-size: 0.9em;
}

.card-wrapper {
  border: 1px solid $gray-400;
  border-bottom-width: 3px;
  padding: 0.8rem;
  border-radius: 3px;
}

.card.builds {
}

.build-grid {
  display: grid;
  grid-template-columns: 180px 4fr 2fr 3fr 1fr;
  // grid-gap: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.build-grid-item {
    cursor: pointer;
    &:hover {
      background: $gray-100;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-300;
  }

  &.heading {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9em;
  }
}
